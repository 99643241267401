<template>
  <div class="inner-layout">
    <!-- 侧边菜单 -->
    <side-bar></side-bar>

    <div class="inner-layout__main">
      <!-- 顶部工具栏 -->
      <header-bar></header-bar>

      <div class="inner-layout__page">
        <!-- 路由页面 -->
        <template v-if="isIE">
          <keep-alive :include="cachePages">
            <router-view :key="key"></router-view>
          </keep-alive>
        </template>
        <template v-else>
          <transition name="page" mode="out-in">
            <keep-alive :include="cachePages">
              <router-view :key="key"></router-view>
            </keep-alive>
          </transition>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { HeaderBar, SideBar } from "./components";
import { mapGetters } from "vuex";
import { isIE } from "@/utils/core";
export default {
  components: { HeaderBar, SideBar },
  computed: {
    ...mapGetters(["cachePages"]),
    // 创建文章以及所有的编辑文章都是共用的ArticleEdit组件,所以对应的路由不能使用keep-alive缓存。
    // 同时它们使用的也是动态路由，在这些路由之间切换时也需要响应路由参数的变化，当路由参数变化时需要重新渲染。
    key() {
      return this.$route.path;
    },
    isIE() {
      return isIE();
    },
  },
};
</script>

<style lang="scss" scoped>
.inner-layout {
  display: flex;
  height: 100vh;
  min-height: 760px;
  overflow-y: hidden;
  min-width: 1440px;

  background-color: #f7f8f9;
  .inner-layout__main {
    width: 100%;
    overflow-x: hidden;
    .inner-layout__page {
      position: relative;
      box-sizing: border-box;
      height: calc(100% - 64px);
      overflow-x: hidden;
      overflow-y: auto;
      padding: 22px;

      .page {
        &-enter {
          opacity: 0;
          transform: translateX(-30px);
        }

        &-leave-to {
          opacity: 0;
          transform: translateX(30px);
        }

        &-enter-active,
        &-leave-active {
          transition: all 0.3s ease;
        }
      }
    }
  }
}
</style>
