<template>
  <div class="header-bar flex-h flex-vc flex-jsb">
    <div class="flex-h flex-vc logo_left">
      <img
        :src="
          sideCollapse
            ? require(`@/assets/images/layout/spread@2x.png`)
            : require(`@/assets/images/layout/retract@2x.png`)
        "
        class="header-bar__collapse"
        @click="handleCollapse"
        alt=""
      />
      <img
        class="logo_img"
        :src="`data:image/jpeg;base64,${userInfo.logo}`"
        alt=""
      />
      <span>
        <chacter-overflow :text="userInfo.company_name || '---'" :limit="30" />
      </span>
    </div>
    <div class="header-bar__menu flex-h flex-vc">
      <el-tooltip
        class="item"
        effect="dark"
        content="帮助文档"
        placement="bottom"
        popper-class="innerLayOut-popper"
        :visible-arrow="false"
      >
        <svg-icon
          name="download@2x"
          size="24"
          class="download"
          @click.native.stop="handleDownload"
        ></svg-icon>
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        content="修改密码"
        placement="bottom"
        popper-class="innerLayOut-popper"
        :visible-arrow="false"
      >
        <img
          src="@/assets/images/layout/Password@2x.png"
          alt=""
          class="password"
          @click="handleEdit"
        />
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        content="退出登录"
        popper-class="innerLayOut-popper"
        placement="bottom"
        :visible-arrow="false"
      >
        <img
          src="@/assets/images/layout/signout@2x.png"
          alt=""
          class="signout"
          @click="logout"
        />
      </el-tooltip>

      <span>{{ userInfo.user_name }}</span>
      <img class="avatar" src="@/assets/images/avatar.png" alt />
    </div>
    <Edit :visible="visible" @onClose="handleClose" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Edit from "./components/Edit.vue";
import { helpFile } from "@/utils/file-mode";

export default {
  components: {
    Edit,
  },
  data() {
    return {
      avatar: "",
      visible: false,
    };
  },
  computed: {
    ...mapGetters(["sideCollapse", "userInfo"]),
  },
  methods: {
    //折叠侧边栏
    handleCollapse() {
      this.$store.commit("cache/SET_SIDE_COLLAPSE", !this.sideCollapse);
    },
    logout() {
      this.$confirm(`确定退出吗？`, "退出", {})
        .then(async () => {
          this.$store.dispatch("account/logout");
        })
        .catch(() => {});
    },
    handleEdit() {
      console.log(this.userInfo);
      this.visible = true;
    },
    // 关闭编辑模态窗
    handleClose() {
      this.visible = false;
    },
    handleDownload() {
      // const IFRAME = document.createElement("iframe");
      // IFRAME.style.display = "none";
      // IFRAME.style.height = 0;
      // // src 就是请求服务地址自行修改。
      // IFRAME.src = helpFile;
      // document.body.appendChild(IFRAME);
      // setTimeout(() => {
      //   IFRAME.remove();
      // }, 60 * 1000);
      window.open(helpFile, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.header-bar {
  height: 64px;
  // line-height: 64px;
  padding-right: 40px;
  background-color: #fff;
  box-shadow: 0px 4px 8px 0px rgba(240, 241, 243, 1);
  position: relative;
  z-index: 100;
  &__collapse {
    color: #333;
    margin-left: 16px;
    display: inline-block;
    width: 24px;
    height: 24px;
    cursor: pointer;

    // &:hover {
    //   color: ;
    // }
  }
  .download {
    margin-right: 24px;
    margin-top: 2px;
    cursor: pointer;
  }
  .logo_left {
    overflow: hidden;
    height: 100%;
    font-size: 16px;
    color: #333333;
    font-weight: 600;
    .logo_img {
      width: auto;
      height: 32px;
      margin-left: 64px;
    }
    span {
      margin-left: 8px;
    }
  }

  .el-color-picker {
    transform: translate(-10px, -3px);
  }

  &__menu {
    color: $black;
    .password,
    .signout {
      width: 24px;
      cursor: pointer;
    }
    .signout {
      margin: 0 24px;
    }
    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      // cursor: pointer;
      margin-left: 8px;
    }
  }
}
</style>
<style lang="scss">
.el-tooltip__popper.is-dark.innerLayOut-popper {
  padding: 5px 8px;
  border-radius: 100px;
  // ::before {
  &::before {
    content: "";
    border-style: solid;
    border-color: transparent;
    border-width: 4px;
    border-left-color: #303133;
    border-top-color: #303133;
    border-top-left-radius: 2px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) rotate(45deg);
  }
  // }
}
</style>
