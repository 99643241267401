<template>
  <div class="menu-item">
    <el-submenu
      v-if="config.children"
      :index="config.path"
      popper-class="menu-popper"
    >
      <template slot="title">
        <i v-if="config.icon" :class="'iconfont ' + config.icon"></i>
        <span>{{ config.title }}</span>
      </template>
      <menu-item
        v-for="childConfig in config.children"
        :key="childConfig.path"
        :config="childConfig"
      ></menu-item>
    </el-submenu>
    <y-menu-item v-else :index="config.path">
      <i v-if="config.icon" :class="'iconfont ' + config.icon"></i>
      <span slot="title">{{ config.title }}</span>
    </y-menu-item>
  </div>
</template>

<script>
import "@/assets/font/iconfont.css";
import YMenuItem from "@/components/base/y-menu-item";
export default {
  name: "MenuItem",
  components: {
    YMenuItem,
  },
  props: {
    config: {
      required: true,
      default: {},
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.menu-item {
  /deep/ .el-menu-item {
    // font-size: 14px;
    color: $white;
    height: 56px;
    line-height: 56px;
    &:focus,
    &:hover {
      background-color: rgba($color: #fff, $alpha: 0.1);
    }
    &.is-active {
      color: $white;
      background-color: rgba($color: #fff, $alpha: 0.3);
      .iconfont {
        color: $white;
        font-size: 24px;
      }
    }
    .iconfont {
      color: #fff;
      font-size: 24px;
      margin-right: 12px;
    }
  }
  /deep/ .el-submenu {
    .el-submenu__title {
      color: $white;
      height: 56px;
      line-height: 56px;
      &:focus,
      &:hover {
        background-color: rgba($color: #fff, $alpha: 0.1);
      }
      .el-submenu__icon-arrow {
        color: $white;
        font-weight: 600;
        font-size: 14px;
      }
    }
    .el-menu {
      background-color: $theme;

      .el-menu-item {
        padding-left: 56px !important;
        height: 48px;
        line-height: 48px;
      }
    }
    .iconfont {
      color: #fff;
      font-size: 24px;
      margin-right: 12px;
    }
  }
}

.el-menu--collapse {
  .menu-item {
    /deep/ .el-submenu > .el-submenu__title {
      span,
      .el-submenu__icon-arrow {
        display: none;
      }
    }
  }
}
</style>
