<template>
  <div class="side-bar">
    <logo />

    <!-- 侧边导航菜单 -->
    <scrollbar>
      <el-menu
        :default-active="activePath"
        :collapse="sideCollapse"
        :unique-opened="false"
        router
      >
        <menu-item
          v-for="menu in menuList"
          :key="menu.path"
          :config="menu"
        ></menu-item>
      </el-menu>
    </scrollbar>
  </div>
</template>

<script>
import Scrollbar from "@/components/base/scrollbar";
import Logo from "./Logo";
import MenuItem from "./MenuItem";
export default {
  components: {
    Scrollbar,
    Logo,
    MenuItem,
  },
  data() {
    return {
      menuRouteMap: [],
      menuList: [],
    };
  },
  computed: {
    sideCollapse() {
      return this.$store.getters.sideCollapse;
    },
    routeMap() {
      // console.log(this.$router.options.routes);
      return this.$router.options.routes;
    },
    activePath() {
      const { meta, path } = this.$route;
      return meta.activePath ? meta.activePath : path;
    },
  },
  created() {
    this.menuRouteMap = this.getMenuRouteMap(this.routeMap);
    this.menuList = this.getMenu(this.menuRouteMap);
  },
  methods: {
    // 获取需要在侧边菜单显示的路由表
    getMenuRouteMap(routes) {
      const menuRouteMap = routes.filter((route) => {
        // 如果父路由设置了hiddenInMenu：true，则它以及它的子路由都不能通过菜单栏访问
        if (route.meta.hiddenInMenu) {
          return false;
        } else {
          if (route.children) {
            route.children = this.getMenuRouteMap(route.children);
            if (route.children.length === 0) {
              // 如果所有子路由都设置了hiddenInMenu：true，则父路由不显示
              return false;
            } else {
              return true;
            }
          } else {
            // 路由未设置hiddenInMenu：true，但是又不存在子路由的情况
            return true;
          }
        }
      });
      return menuRouteMap;
    },
    getMenuItem(route) {
      // children不存在代表是最后一级路由，只有一个children代表只有第一级路由
      if (!route.children || route.children.length === 1) {
        return {
          title: route.meta.title,
          icon: route.meta.icon,
          path: route.path,
        };
      } else {
        return {
          title: route.meta.title,
          icon: route.meta.icon,
          path: route.path,
          children: route.children.map((childrenRoute) =>
            this.getMenuItem(childrenRoute)
          ),
        };
      }
    },
    // 根据路由表生成导航菜单
    getMenu() {
      return this.menuRouteMap.map((route) => this.getMenuItem(route));
    },
  },
};
</script>

<style lang="scss" scoped>
.side-bar {
  background-color: $theme;
  box-shadow: 4px 0px 8px 0px rgba(199, 208, 216, 1);
  z-index: 100;
  position: relative;
  /deep/ .scrollbar-wrap {
    height: calc(100% - 62px);
    overflow: visible;
    .scrollbar__content {
      overflow-y: scroll;
      overflow-x: visible;
    }
  }

  .el-menu {
    height: 100%;
    border-right: none;
    background-color: $theme;
    // margin-top: 36px;
    &:not(.el-menu--collapse) {
      width: 208px;
    }
  }
}
</style>
<style lang="scss">
.el-menu--vertical.menu-popper {
  .el-menu::before {
    border-color: transparent;
    top: 18px;
    transform: translateX(-100%);
    border-right-color: $white;
    border-left-width: 0;
    content: " ";
    border-width: 6px;
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
  .el-menu--popup-right-start {
    margin-left: 10px;
  }
  .el-menu.el-menu--popup {
    box-shadow: 0px 4px 8px 2px rgba(112, 112, 112, 0.28);
    background-color: transparent !important;
    padding: 0;
    min-width: auto;
    border-radius: 10px;
    .menu-item {
      &:first-child .el-menu-item {
        border-radius: 10px 10px 0 0;
        overflow: hidden;
      }
      &:last-child {
        border-radius: 0px 0px 10px 10px;
        overflow: hidden;
      }
    }
    .el-menu-item {
      background-color: #fff !important;
      color: $black !important;
      font-weight: 600;
      padding: 10px 20px;
      min-width: 96px;
      height: auto;
      line-height: initial;
      &:hover,
      &:focus {
        background: #f4f6f8 !important;
        font-weight: 400;
      }
      &.is-active {
        color: $theme !important;
        background: #edf2f7 !important;
      }
    }
  }
}
.el-tooltip__popper.menu-tip {
  color: $white;
  padding: 11px 16px;
  width: auto;
  font-size: 14px;
  // &.is-light[x-placement^="right"] .popper__arrow {
  //   border-right-color: #fff;
  // }
}
</style>
