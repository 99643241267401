<template>
  <el-dialog
    class="custom-dialog"
    :title="`修改密码`"
    :visible.sync="visible"
    :before-close="handleBeforeClose"
    :close-on-click-modal="false"
    append-to-body
  >
    <div>
      <el-form
        ref="form"
        :model="info"
        v-loading="getDetailLoading"
        :rules="formRules"
        hide-required-asterisk
      >
        <el-form-item label="" class="from_item__height_none"
          >{{ userInfo.account || "---" }}
        </el-form-item>
        <el-form-item label="" prop="opwd">
          <el-input
            v-model="info.opwd"
            placeholder="输入原密码"
            type="password"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="pwd">
          <el-input
            v-model="info.pwd"
            placeholder="6~20位新密码"
            type="password"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="repet_pwd">
          <el-input
            v-model="info.repet_pwd"
            placeholder="确认新密码"
            type="password"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleBeforeClose">取消</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import api from "@/api";
import dialogLife from "@/mixins/dialogLife";
import { deepClone } from "@/utils/core";
import { mapGetters } from "vuex";
const defaultInfo = {
  opwd: "",
  pwd: "",
};
export default {
  mixins: [dialogLife],
  components: {},
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请确认新密码"));
      } else if (value !== this.info.pwd) {
        callback(new Error("两次新密码输入不一致，请重新输入"));
      } else {
        callback();
      }
    };
    return {
      infoBackup: deepClone(defaultInfo),
      info: deepClone(defaultInfo),
      formRules: {
        opwd: [
          { required: true, message: "请输入原密码", trigger: "blur" },
          { min: 6, message: "长度最少6个字符", trigger: "blur" },
        ],
        pwd: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { min: 6, message: "长度最少6个字符", trigger: "blur" },
        ],
        repet_pwd: [
          {
            validator: validatePass,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async getDetail() {},
    handleSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.submitLoading = true;
          try {
            const { pwd, opwd, repet_pwd } = this.info;
            if (pwd && opwd && pwd === repet_pwd) {
              await api.account.publicPwdEdit({
                pwd,
                opwd,
              });
            }
            this.submitLoading = false;
            this.handleClose();
            this.$alert(`密码修改成功,请重新登录。`, "提示", {})
              .then(async () => {
                // this.$message.success("修改成功");
                this.$store.dispatch("account/logout");
              })
              .catch(() => {
                this.$store.dispatch("account/logout");
              });
          } catch (e) {
            console.log(e);
            this.submitLoading = false;
          }
        }
        // else {
        //   this.$message.error("请确认格式是否正确");
        // }
      });
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.info = deepClone(defaultInfo);
      this.infoBackup = deepClone(defaultInfo);
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-dialog {
  .el-dialog__footer {
    // padding: 0;
  }
}
</style>
