<template>
  <div class="side-bar-logo" :class="{ collapse: sideCollapse }">
    <div class="link" to="/">
      <div class="flex flex-v logo-cantainer" v-show="!sideCollapse">
        <img
          class="logo"
          src="@/assets/images/layout/Zartalogo@3x.png"
          alt=""
          @click="$router.push('/')"
        />
        <!-- <span><span @click="$router.push('/')">Zarta股权激励系统</span></span> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    sideCollapse() {
      return this.$store.getters.sideCollapse;
    },
  },
};
</script>

<style lang="scss" scoped>
.side-bar-logo {
  &.collapse .link {
    width: 64px;
  }

  .link {
    display: block;
    width: 208px;
    height: 92px;
    padding: 30px 0;
    padding-left: 20px;
    box-sizing: border-box;
    background-color: #4d90ff;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    /* 查看element的menu.css源码可知transition的参数 */
    transition: width 0.3s ease-in-out;
    &:hover {
      color: $white !important;
    }
    .logo {
      width: 125px;
      display: inline-block;
      image-rendering: -moz-crisp-edges; /* Firefox */
      image-rendering: -o-crisp-edges; /* Opera */
      image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
      height: 32px;
      // margin-bottom: 5px;
    }
    .logo-cantainer {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
